<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :footer-left-actions="footerLeftActions"
      :sums="sums"
      :is-page-show="false"
      api-url="/dc-get-receipt-history"
    ></custom-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  GOODS_ISSUE_STATE,
  CUSTOM_VIEW_DC_ORDER_LIST,
  YES_NO_OPTIONS,
} from "@/libs/const";

export default {
  name: "ReceiptHistory",
  components: {},
  data: () => ({
    customView: CUSTOM_VIEW_DC_ORDER_LIST,
    columns: [],
    footerLeftActions: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    filters: {},
    statusOptions: [...GOODS_ISSUE_STATE],
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  created() {
    const columns = [
      {
        type: "date-range-filter",
        label: this.$t("labels.create_time_2"),
        placeholder: this.$t("labels.create_time_2"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
        defaultValue: [],
        clearable: false,
      },
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        allowPaste: true,
        required: true,
      },
      {
        type: "select-warehouse",
        label: this.$t("labels.warehouse_1"),
        placeholder: this.$t("labels.warehouse_1"),
        name: "id_warehouse",
        hasSort: false,
        sortName: "warehouse_code",
        key: "warehouse_code",
        required: true,
      },
      {
        type: "select-distributor",
        label: this.$t("labels.dc_distributor"),
        placeholder: this.$t("labels.dc_distributor"),
        name: "id_distributor",
        hasSort: false,
        sortName: "id_distributor",
        key: "dc_distributor_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.container_code"),
        placeholder: this.$t("labels.container_code"),
        name: "container_code",
        hasSort: false,
        sortName: "container_code",
        key: "container_code",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.requested"),
        placeholder: this.$t("labels.requested"),
        name: "box_quantity",
        hasSort: false,
        sortName: "box_quantity",
        key: "box_quantity",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receipted"),
        placeholder: this.$t("labels.receipted"),
        name: "receipt_box_quantity",
        hasSort: false,
        sortName: "receipt_box_quantity",
        key: "receipt_box_quantity",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerLeftActions = [];
    this.footerLeftActions = [...footerLeftActions];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },

    async downloadExcel(filters) {
      if (window.exportWaiting) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      try {
        await httpClient.post("/dc-order-list-export", filters);
        window.exportWaiting = true;
        this.$vToastify.warning(this.$t("messages.loading"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async orderCancel(item) {
      if (!confirm(this.$t("messages.are_you_sure_cancel_order"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/dc-order-cancel", {
          id: item.id,
        });
        this.isLoading = false;
        this.refreshCustomTable();
        this.$vToastify.warning(this.$t("messages.order_cancel_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
